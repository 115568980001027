import 'twin.macro';
// import { MenuIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'gatsby';

import config from '../utils/config';
import SocialIcons from './SocialIcons';
import { Heading } from './atoms';

const Footer = ({ data }) => (
  <footer tw="relative bg-secondary pt-8 pb-6 text-primary">
    <div tw="xl:px-40 pb-12 lg:px-20 md:px-10 sm:px-5 px-10">
      <div tw="w-full pt-12 flex flex-col sm:flex-row space-y-2  justify-start">
        <div tw="w-full sm:w-2/5 pr-6 flex flex-col space-y-4">
          <Link to="/">
            <h3 tw="text-3xl font-bold text-primary ">{config.siteName}</h3>
            {/* <span tw="sr-only">{config.siteName}</span>
            <img tw="h-8 w-auto sm:h-10" src="/images/logo-1024.png" alt={config.siteName} /> */}
          </Link>
          {data.address && <p tw="opacity-90">{data.address}</p>}
          <a tw="opacity-90" href={`mailto:${data.email}`}>
            📧 {data.email || config.email}
          </a>
          <a
            tw="opacity-90"
            href={`https://api.whatsapp.com/send?phone=91${
              data.telephone || config.telephone
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-whatsapp" tw="text-green-400" /> +91-
            {data.telephone || config.telephone}
          </a>
        </div>
        <div tw="w-full sm:w-1/5 flex flex-col space-y-4">
          <div tw="-mb-2">
            <Heading type="h3" textSmall>
              Quick Links
            </Heading>
          </div>
          <Link to="/about" tw="opacity-90">
            About Shray
          </Link>
          <Link to="/gallery" tw="opacity-90">
            Gallery
          </Link>
          <Link to="/schedule" tw="opacity-90">
            Schedule
          </Link>
          <Link to="/enrol" tw="opacity-90">
            Enrol Now
          </Link>
        </div>
        <div tw="w-full sm:w-1/5 flex flex-col space-y-4">
          <div tw="-mb-2">
            <Heading type="h3" textSmall>
              Teachings
            </Heading>
          </div>
          <Link to="/practice" tw="opacity-90">
            Practice
          </Link>
          <Link to="/philosophy" tw="opacity-90">
            Philosophy
          </Link>
          <Link to="/sanskrit" tw="opacity-90">
            Sanskrit
          </Link>
          <Link to="/holidays" tw="opacity-90">
            Holidays
          </Link>
        </div>
        <div tw="w-full sm:w-1/5 pt-6 flex items-end mb-1">
          <SocialIcons data={data} />
        </div>
      </div>
      <div tw="opacity-90 pt-4">
        <p>
          © 2021{' '}
          <a target="_blank" href="https://www.zeiq.co/" rel="noreferrer">
            Zeiq.co
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;

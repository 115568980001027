import tw from 'twin.macro';
import React from 'react';

const Heading = ({ children, ...props }) => {
  if (props.type === 'h1')
    return (
      <h1
        css={[
          tw`md:text-6xl text-5xl text-primary font-bold md:leading-tight`,
          props.center && tw`text-center`,
        ]}
      >
        {children}
      </h1>
    );
  if (props.type === 'h3')
    return (
      <h3
        css={[
          tw`text-6xl text-primary font-bold`,
          props.center && tw`text-center`,
          props.upperCase && tw`uppercase`,
          props.textSmall ? tw`text-lg mb-3` : tw`text-2xl mb-8`,
        ]}
      >
        {children}
      </h3>
    );
  return null;
};
export default Heading;

module.exports = {
  DEBUG: process.env.NODE_ENV === 'development',
  siteName: 'Shray Yoga',
  author: 'Parminder Klair',
  description:
    'Shray Yoga is a great website for both yoga beginners and advanced yogis.',
  siteUrl: 'http://yoga-dev.netlify.com',
  logo: '/images/logo.png',

  telephone: '9663608243',
  email: 'shray2000t@gmail.com',
  location: 'London, UK',
  twitter: '@NAME-HERE',
  fbUserId: '@NAME-HERE',
  fbAppID: '@NAME-HERE',
  sanityId: 'hrmlvlh0',
  type: 'website',
  googleAnalytics: 'UA-1231313',
  backgroundColor: '#e0e0e0',
  themeColor: '#c62828',
  SEND_IN_BLUE_API_KEY:
    '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',
};

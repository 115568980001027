import React from 'react';
import Helmet from 'react-helmet';
import { GlobalStyles, styled } from 'twin.macro';
import { graphql, StaticQuery } from 'gatsby';

import config from '../utils/config';
import Header from './Header';
import StylesBase from '../utils/styles';
// import ThemeToggle from './ThemeToggle';
import Footer from './Footer';

const Container = styled.div`
  min-height: 70vh;
  .gatsby-image-wrapper {
    z-index: -1;
  }
`;

const query = graphql`
  query LayoutQuery {
    sanitySiteSettings {
      facebook
      twitter
      instagram
      pinterest
      youtube
      telephone
      address
      email
    }
  }
`;

const IndexLayout = ({ children, hideHeader }) => (
  <>
    <Helmet>
      <title>{config.siteName}</title>
      <meta description={config.description} />
    </Helmet>
    <GlobalStyles />
    <StylesBase />

    <StaticQuery
      query={query}
      render={(data) => {
        const home = data.sanitySiteSettings;
        return (
          <>
            {!hideHeader && <Header />}
            <Container>{children}</Container>
            <Footer data={home} />
          </>
        );
      }}
    />
    {/* <div tw="h-full flex justify-center items-center">
      <ThemeToggle />
    </div> */}
  </>
);

export default IndexLayout;

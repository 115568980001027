import 'twin.macro';
import React from 'react';

const SocialIcons = ({ data }) => {
  const socialItems = [
    { id: 1, icon: 'fab fa-twitter', url: data.twitter },
    { id: 2, icon: 'fab fa-facebook-f', url: data.facebook },
    { id: 3, icon: 'fab fa-instagram', url: data.instagram },
    { id: 4, icon: 'fab fa-pinterest-p', url: data.pinterest },
    { id: 5, icon: 'fab fa-youtube', url: data.youtube },
  ];
  return (
    <div tw="flex flex-row space-x-6">
      {socialItems.map((item) => {
        if (!item.url) {
          return null;
        }
        return (
          <a
            key={item.id}
            target="_blank"
            href={item.url}
            tw="bg-tertiary	text-white shadow-lg h-10 w-10 rounded-full py-2 text-center"
            rel="noreferrer"
          >
            <i className={item.icon} />
          </a>
        );
      })}
    </div>
  );
};
export default SocialIcons;

import tw from 'twin.macro';
import React from 'react';

const Button = ({ children, isFullWidth, isRounded, ...otherProps }) => (
  <button
    type="button"
    css={[
      tw`py-2 md:px-6 px-4 bg-tertiary text-gray-100 border-2 border-primary text-lg rounded-lg hover:bg-tertiary hover:text-gray-100 focus:border-2 focus:border-primary`,
      isFullWidth && tw`w-full mt-2`,
      isRounded && tw`rounded-full`,
    ]}
    {...otherProps}
  >
    {children}
  </button>
);
export default Button;

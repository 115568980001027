import 'twin.macro';
import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import config from '../utils/config';
import { Button } from './atoms';

const Header = () => (
  <Popover tw="bg-white border-b-2 border-gray-100">
    {({ open }) => (
      <>
        <div tw="mx-auto px-4 sm:px-6">
          <div tw="flex justify-between items-center py-4 md:justify-start md:space-x-10">
            <div tw="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <h3 tw="text-3xl font-bold text-primary ">{config.siteName}</h3>
                {/* <span tw="sr-only">{config.siteName}</span>
                <img
                  tw="h-8 w-auto sm:h-10"
                  src="/images/logo-1024.png"
                  alt=""
                /> */}
              </Link>
            </div>
            <div tw="-mr-2 -my-2 md:hidden">
              <Popover.Button tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:text-primary hover:bg-gray-100 focus:outline-none">
                <span tw="sr-only">Open menu</span>
                <MenuIcon tw="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group
              as="nav"
              tw="hidden md:flex lg:space-x-10 space-x-4 flex-auto justify-end text-primary items-center"
            >
              <Link to="/">Home</Link>
              <Link to="/schedule">Schedule</Link>
              {/* <Link to="/about">About Shray</Link> */}
              {/* <div tw="relative inline-block text-left">
                <Menu as="div">
                  <div>
                    <Menu.Button tw="inline-flex justify-center w-full">
                      Teachings
                      <ChevronDownIcon
                        tw="w-5 h-5 ml-1 mt-1"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items tw="absolute z-10 right-0 w-56 mt-8 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div tw="px-4 py-2">
                        <Menu.Item>
                          <Link to="/practice">Practice</Link>
                        </Menu.Item>
                      </div>
                      <div tw="px-4 py-2">
                        <Menu.Item>
                          <Link to="/philosophy">Philosophy</Link>
                        </Menu.Item>
                      </div>
                      <div tw="px-4 py-2">
                        <Menu.Item>
                          <Link to="/sanskrit">Sanskrit</Link>
                        </Menu.Item>
                      </div>
                      <div tw="px-4 py-2">
                        <Menu.Item>
                          <Link to="/holidays">Holidays</Link>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> */}
              <Link to="/gallery">Gallery</Link>
              <Link to="/enrol">
                <Button>Enrol Now</Button>
              </Link>
            </Popover.Group>
          </div>
        </div>

        <Transition
          show={open}
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            static
            tw="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div tw="pt-5 pb-6 px-5">
                <div tw="flex items-center justify-between">
                  <div>
                    <h3 tw="text-3xl font-bold text-primary ">
                      {config.siteName}
                    </h3>
                    {/* <img
                      tw="h-8 w-auto"
                      src="/images/logo-1024.png"
                      alt={config.siteName}
                    /> */}
                  </div>
                  <div tw="-mr-2">
                    <Popover.Button tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:text-primary hover:bg-gray-100 focus:outline-none focus:border">
                      <span tw="sr-only">Close menu</span>
                      <XIcon tw="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div tw="py-6 px-5 space-y-6">
                <div tw="grid grid-cols-2 gap-y-4 gap-x-8">
                  <Link to="/">Home</Link>
                  {/* <Link to="/about">About Shray</Link> */}
                  <Link to="/schedule">Schedule</Link>
                  {/* <Link to="/practice">Practice</Link>
                  <Link to="/philosophy">Philosophy</Link>
                  <Link to="/sanskrit">Sanskrit</Link>
                  <Link to="/holidays">Holidays</Link> */}
                  <Link to="/gallery">Gallery</Link>
                  <Link to="/enrol">Enrol Now</Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default Header;
